<template>
	<div class="clock">
		<div class="title">
			{{ $route.meta.title }}
			<i @click="showCourse = !showCourse" class="iconfont icon-question-circle"></i>
		</div>
		<div class="course" v-show="showCourse">
			<p><span class="black">1、通过创建打卡任务，提升已有客户活跃度。</span></p>
			<p><span class="black">2、打卡形式分为签到、拍照打卡两种类型，可根据活动性质与客户属性创建打卡任务。</span></p>
			<p><span class="black">3、部分信息在发布之后无法编辑，请谨慎操作。</span></p>
		</div>
		<div class="inputs">
			<el-form :inline="true" size="small">
				<el-form-item label=""><el-input v-model="keyword" placeholder="请输入活动名称/创建人"></el-input></el-form-item>
				<el-form-item><el-button class="btn-blue" @click="handleSearch">搜索</el-button></el-form-item>
			</el-form>
			<el-button
				size="small"
				class="btn-blue right"
				@click="
					() => {
						row = '';
						type = 1;
						addVisible = true;
					}
				"
			>
				添加群打卡
			</el-button>
		</div>
		<div class="table">
			<el-table
				v-loading="loading"
				:data="tableData"
				tooltip-effect="dark"
				style="width: 100%"
				:header-cell-style="{
					color: '#333',
					fontSize: '14px',
					backgroundColor: '#F6F7FB',
					fontWeight: 'normal'
				}"
			>
				<el-table-column prop="name" label="打卡任务名称" show-overflow-tooltip>
					<span slot-scope="scope" class="ellipsis">{{ scope.row.name }}</span>
				</el-table-column>
				<el-table-column prop="phone" label="活动二维码">
					<template slot-scope="scope">
						<el-image fit="cover" ref="el-image" style="width: 100px; height: 100px" :z-index="9999" :preview-src-list="[url]">
							<div class="qrImg" slot="error" @click="handlePreview(urls[scope.$index])">
								<vue-qr
									class="qr-code"
									style="cursor: pointer;width: 100%;"
									:text="scope.row.url"
									:margin="0"
									colorDark="#666"
									colorLight="#fff"
									:logoSrc="require('../../assets/images/logo.png')"
									:logoScale="0.2"
									:size="500"
									:callback="
										url => {
											urls[scope.$index] = url;
										}
									"
								></vue-qr>
							</div>
						</el-image>
					</template>
				</el-table-column>
				<el-table-column label="打卡方式">
					<template slot-scope="scope">
						{{ scope.row.mode == 1 ? '签到打卡' : '拍照打卡' }}
					</template>
				</el-table-column>
				<el-table-column label="活动时间" width="200px">
					<template slot-scope="scope">
						{{ scope.row.start_time.replace(/-/g, '/') }}-{{ scope.row.end_time.replace(/-/g, '/') }}
					</template>
				</el-table-column>
				<el-table-column label="活动说明" show-overflow-tooltip>
					<span slot-scope="scope" class="ellipsis">{{ scope.row.rule }}</span>
				</el-table-column>
				<el-table-column prop="staff_name" label="引流成员"></el-table-column>
				<el-table-column prop="balance" label="参与人数/打卡次数">
					<template slot-scope="scope">
						{{ scope.row.number }}/{{ scope.row.times }}
					</template>
				</el-table-column>
				<el-table-column prop="add_user" label="创建人"></el-table-column>
				<el-table-column prop="create_at" label="创建时间" width="160px"></el-table-column>
				<el-table-column label="操作">
					<template slot-scope="scope">
						<el-button
							type="text"
							style="margin-left: 10px; color: #6881ec"
							@click="
								() => {
									row = JSON.parse(JSON.stringify(scope.row));
									type = 2;
									addVisible = true;
								}
							"
						>
							编辑
						</el-button>
						<el-button v-if="scope.row.is_release == 0" type="text" style="color: #6881ec" @click="releasePunch(scope.row)">发布</el-button>
						<el-button type="text" style="color: #6881ec" @click="downloadImg(scope.$index, scope.row.name)">下载</el-button>
						<el-button
							type="text"
							style="color: #6881ec"
							@click="
								() => {
									id = scope.row.id;
									name = scope.row.name;
									mode = scope.row.mode;
									sVisible = true;
								}
							"
						>
							统计
						</el-button>
						<el-button type="text" style="color: #6881ec" @click="$copy($event, scope.row.url)">复制链接</el-button>
					</template>
				</el-table-column>
			</el-table>
		</div>
		<div class="pagination" v-if="total !== 0">
			<el-pagination
				@size-change="
					pageSize => {
						limit = pageSize;
						page = 1;
						getClockList();
					}
				"
				@current-change="
					currentPage => {
						page = currentPage;
						getClockList();
					}
				"
				:current-page="page"
				:page-sizes="[10, 20, 40, 80]"
				:page-size="limit"
				layout="total, sizes, prev, pager, next, jumper"
				:total="total"
			></el-pagination>
		</div>
		<!-- 引入组件 -->
		<add-clock :visible="addVisible" @close="addClose" :row="row" :type="type" />
		<clock-statistic :visible="sVisible" @close="sClose" :id="id" :name="name" :mode="mode" />
	</div>
</template>

<script>
import AddClock from '@/components/AddClock';
import ClockStatistic from '@/components/ClockStatistic';
import { reqGetPunchList, reqReleasePunch } from '@/api/index';
import vueQr from 'vue-qr';
export default {
	components: {
		AddClock,
		ClockStatistic,
		vueQr
	},
	data() {
		return {
			showCourse: false,
			keyword: '',
			tableData: [],
			page: 1,
			limit: 10,
			total: 0,
			loading: false,
			addVisible: false,
			sVisible: false,
			row: '',
			id: '',
			name: '',
			mode: '',
			urls: [],
			url: '',
			type: ''
		};
	},
	watch: {
		keyword(val) {
			!val ? this.getClockList() : '';
		}
	},
	mounted() {
		this.$bus.$on('updateData', () => {
			this.getClockList();
		});
		this.getClockList();
	},
	methods: {
		handleSearch() {
			if (this.keyword) {
				this.page = 1;
				this.getClockList();
			}
		},
		addClose() {
			this.addVisible = false;
		},
		sClose() {
			this.sVisible = false;
		},
		getClockList() {
			this.tableData = [];
			this.urls = [];
			this.loading = true;
			reqGetPunchList({
				page: this.page,
				limit: this.limit,
				keyword: this.keyword
			}).then(res => {
				this.tableData = res.data;
				this.total = res.count;
				this.loading = false;
			});
		},
		downloadImg(index, name) {
			var imgUrl = document.querySelectorAll('.qrImg>img')[index].src;
			var a = document.createElement('a');
			a.href = imgUrl; //  href链接指向图片的地址
			a.download = name; //  下载的图片的名称
			a.click(); //  触发a标签的单击事件
		},
		releasePunch(row) {
			this.$confirm(`活动一旦发布部分信息无法编辑，确认发布“${row.name}”`, '发布活动', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			})
				.then(() => {
					reqReleasePunch({
						id: row.id
					}).then(res => {
						this.getClockList();
						this.$message.success(res.msg);
					});
				})
				.catch(() => {});
		},
		handlePreview(url) {
			setTimeout(() => {
				this.$closeImg();
			});
			this.$nextTick(() => {
				this.url = url;
				this.$refs['el-image'].clickHandler();
			});
		}
	}
};
</script>

<style lang="less" scoped>
.clock {
	width: 100%;

	.inputs {
		display: flex;
		align-items: center;
	}

	> div + div {
		margin-top: 15px;
	}

	.ellipsis {
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}
}
</style>
