<template>
	<div class="clock">
		<el-dialog title="打卡统计" :visible.sync="visible" width="1000px" :before-close="close">
			<div class="scroll">
				<div class="my-title">{{ name }}</div>
				<div class="chat">
					<div>
						<div style="display: flex;align-items: center;justify-content: space-between;">
							<span>打卡情况总计</span>
							<el-select @change="getTaskData()" style="width: 150px;" size="small" v-model="task_id" placeholder="请选择">
								<el-option v-for="(item, index) in taskList" :key="index" :label="'阶段任务' + item.sort" :value="item.id"></el-option>
							</el-select>
						</div>
						<div id="chat-pie"></div>
					</div>
					<p class="line"></p>
					<div>
						<div style="display: flex;align-items: center;justify-content: space-between;">
							<span>活动参与详情</span>
							<el-date-picker
								:clearable="false"
								@change="getPartake()"
								style="width: 230px;"
								size="small"
								v-model="date"
								value-format="yyyy-MM-dd"
								type="daterange"
								range-separator="-"
								start-placeholder="开始日期"
								end-placeholder="结束日期"
								:picker-options="{
									disabledDate: date => {
										return Date.parse(date) > Date.parse(new Date());
									}
								}"
							></el-date-picker>
						</div>
						<div id="chat-line"></div>
					</div>
				</div>
				<el-tabs v-model="activeName" type="card">
					<el-tab-pane label="参与详情" name="1">
						<div class="search">
							<el-form :inline="true">
								<el-form-item label="客户名称"><el-input size="small" v-model="keyword" placeholder="请输入"></el-input></el-form-item>
								<el-form-item><el-button size="small" class="btn-blue" @click="handleSearch">查询</el-button></el-form-item>
							</el-form>
						</div>
						<div class="table">
							<el-table
								v-loading="loading"
								:data="tableData"
								tooltip-effect="dark"
								style="width: 100%"
								:header-cell-style="{
									color: '#333',
									fontSize: '14px',
									backgroundColor: '#F6F7FB',
									fontWeight: 'normal'
								}"
							>
								<el-table-column prop="nickname" label="客户名称"></el-table-column>
								<el-table-column prop="num" label="打卡次数"></el-table-column>
								<el-table-column label="已完成任务">
									<template slot-scope="scope">
										<div v-if="scope.row.task.length != 0">
											<p v-for="(item, index) in scope.row.task" :key="index">阶段任务{{ item.sort }}（{{ item.punch_num }}）</p>
										</div>
										<div v-else>--</div>
									</template>
								</el-table-column>
								<el-table-column label="未完成任务">
									<template slot-scope="scope">
										<div v-if="scope.row.finish_task.length != 0">
											<p v-for="(item, index) in scope.row.finish_task" :key="index">阶段任务{{ item.sort }}（{{ item.punch_num }}）</p>
										</div>
										<div v-else>--</div>
									</template>
								</el-table-column>
								<el-table-column v-if="mode == 2" label="打卡记录">
									<el-button slot-scope="scope" type="text" @click="handleShowRecord(scope.row)">查看打卡记录</el-button>
								</el-table-column>
							</el-table>
						</div>
					</el-tab-pane>
				</el-tabs>
				<div class="pagination" v-if="total !== 0">
					<el-pagination
						@size-change="
							pageSize => {
								limit = pageSize;
								page = 1;
								getTableData();
							}
						"
						@current-change="
							currentPage => {
								page = currentPage;
								getTableData();
							}
						"
						:current-page="page"
						:page-sizes="[10, 20, 40, 80]"
						:page-size="limit"
						layout="total, sizes, prev, pager, next, jumper"
						:total="total"
					></el-pagination>
				</div>
			</div>
		</el-dialog>
		<!-- 查看拍照打卡记录 -->
		<el-dialog title="打卡记录" :visible.sync="pVisible" width="600px" :before-close="handleClose">
			<div class="scroll">
				<div class="table">
					<el-table
						v-loading="loading1"
						:data="tableData1"
						tooltip-effect="dark"
						style="width: 100%"
						:header-cell-style="{
							color: '#333',
							fontSize: '14px',
							backgroundColor: '#F6F7FB',
							fontWeight: 'normal'
						}"
					>
						<el-table-column prop="nickname" label="打卡用户"></el-table-column>
						<el-table-column prop="num" label="打卡照片">
							<template slot-scope="scope">
								<el-image
									fit="cover"
									:z-index="9999"
									style="width: 100px; height: 100px"
									:src="scope.row.punch_photo"
									@click.stop="$closeImg"
									:preview-src-list="[scope.row.punch_photo]"
								></el-image>
							</template>
						</el-table-column>
						<el-table-column prop="punch_date" label="打卡时间"></el-table-column>
					</el-table>
				</div>
				<div class="pagination" v-if="total1 !== 0">
					<el-pagination
						@size-change="
							pageSize => {
								limit1 = pageSize;
								page1 = 1;
								getPunchPhotoList();
							}
						"
						@current-change="
							currentPage => {
								page1 = currentPage;
								getPunchPhotoList();
							}
						"
						:current-page="page1"
						:page-sizes="[10, 20, 40, 80]"
						:page-size="limit1"
						layout="total, sizes, prev, pager, next, jumper"
						:total="total1"
					></el-pagination>
				</div>
			</div>
		</el-dialog>
	</div>
</template>

<script>
import { reqGetPunchStatistic, reqGetPartakeInfo, reqGetPunchCustomList, reqGetPunchPhotoList } from '@/api/index';
export default {
	props: ['visible', 'id', 'name', 'mode'],
	data() {
		return {
			tableData: [],
			tableData1: [],
			page: 1,
			limit: 10,
			total: 0,
			loading: false,
			activeName: '1',
			keyword: '',
			taskList: [],
			task_id: '',
			date: [],
			page1: 1,
			limit1: 10,
			total1: 0,
			pVisible: false,
			loading1: false,
			openid: ''
		};
	},
	watch: {
		visible(val) {
			if (val) {
				this.getTableData();
				this.getTaskData();
				this.date = [
					this.$fun_date(-6),
					new Date()
						.toLocaleDateString()
						.split('/')
						.join('-')
				];
				this.getPartake();
			}
		},
		keyword(val) {
			!val ? this.getTableData() : '';
		}
	},
	methods: {
		close() {
			Object.assign(this.$data, this.$options.data());
			this.$emit('close');
		},
		handleSearch() {
			this.keyword ? this.getTableData() : '';
		},
		getTaskData() {
			var params = this.task_id
				? {
						id: this.id,
						task_id: this.task_id
				  }
				: {
						id: this.id
				  };
			reqGetPunchStatistic(params).then(res => {
				this.taskList = res.data.task;
				this.task_id ? '' : (this.task_id = res.data.task[0].id);
				this.$nextTick(() => {
					this.getChatPie([
						{
							name: '未完成客户',
							value: res.data.incomplete || 0
						},
						{
							name: '完成客户',
							value: res.data.finish_num || 0
						}
					]);
				});
			});
		},
		getPartake() {
			reqGetPartakeInfo({
				id: this.id,
				start_time: this.date[0],
				end_time: this.date[1]
			}).then(res => {
				var date = [],
					data = [];
				res.data.forEach(item => {
					date.push(item.punch_date);
					data.push(item.num);
				});
				this.$nextTick(() => {
					this.getChatLine(date, data);
				});
			});
		},
		getTableData() {
			this.loading = true;
			this.tableData = [];
			reqGetPunchCustomList({
				id: this.id,
				page: this.page,
				limit: this.limit,
				keyword: this.keyword
			}).then(res => {
				this.tableData = res.data;
				this.total = res.count || 0;
				this.loading = false;
			});
		},
		handleShowRecord(row) {
			this.openid = row.openid;
			this.getPunchPhotoList();
			this.pVisible = true;
		},
		// 获取拍照打卡记录
		getPunchPhotoList() {
			this.loading1 = true;
			reqGetPunchPhotoList({
				id: this.id,
				openid: this.openid,
				page: this.page1,
				limit: this.limit1
			}).then(res => {
				this.total1 = res.count || 0;
				this.tableData1 = res.data;
				this.loading1 = false;
			});
		},
		handleClose() {
			this.tableData1 = [];
			this.page1 = 1;
			this.limit1 = 10;
			this.total1 = 0;
			this.pVisible = false;
		},
		getChatPie(data) {
			var myChart = this.$echarts.init(document.getElementById('chat-pie'));
			var option = {
				tooltip: {
					trigger: 'item'
				},
				legend: {
					width: '100px',
					top: 'center',
					right: '20%',
					icon: 'circle',
					orient: 'vertical'
				},
				series: [
					{
						center: ['32%', '50%'],
						name: '打卡情况总计',
						type: 'pie',
						radius: ['50%', '65%'],
						avoidLabelOverlap: false,
						itemStyle: {
							borderRadius: 10,
							borderColor: '#fff',
							borderWidth: 2
						},
						label: {
							show: false,
							position: 'center'
						},
						emphasis: {
							label: {
								show: true,
								fontSize: '18',
								fontWeight: 'bold'
							}
						},
						labelLine: {
							show: false
						},
						data
					}
				]
			};
			myChart.setOption(option);
		},
		getChatLine(date, data) {
			var myChart = this.$echarts.init(document.getElementById('chat-line'));
			var option = {
				tooltip: {
					trigger: 'axis'
				},
				xAxis: {
					type: 'category',
					data: date,
					axisLine: {
						//---坐标轴 轴线
						show: false //---是否显示
					},
					axisTick: {
						//---坐标轴 刻度
						show: false //---是否显示
					}
				},
				yAxis: {
					type: 'value',
					minInterval: 1,
					axisTick: {
						//---坐标轴 刻度
						show: false //---是否显示
					}
				},
				grid: {
					top: '30px',
					bottom: '35px',
					left: '40px'
				},
				series: [
					{
						data,
						type: 'line'
					}
				]
			};
			myChart.setOption(option);
		}
	}
};
</script>

<style lang="less" scoped>
.el-dialog {
	.chat {
		display: flex;
		justify-content: space-between;
		margin: 15px 0;
		height: 240px;
		padding: 10px 15px;
		background-color: #f6f7fb;
		border-radius: 5px;

		> div {
			flex: 1;
		}

		.line {
			width: 1px;
			height: 100%;
			background-color: #eee;
			margin: 0 15px;
		}
	}

	.table {
		margin-top: 10px;
	}

	.my-title {
		font-size: 14px;
		font-weight: bold;
	}

	#chat-pie,
	#chat-line {
		width: 100%;
		height: calc(100% - 32px);
	}
}
</style>
