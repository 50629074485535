var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"clock"},[_c('div',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.$route.meta.title)+" "),_c('i',{staticClass:"iconfont icon-question-circle",on:{"click":function($event){_vm.showCourse = !_vm.showCourse}}})]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showCourse),expression:"showCourse"}],staticClass:"course"},[_vm._m(0),_vm._m(1),_vm._m(2)]),_c('div',{staticClass:"inputs"},[_c('el-form',{attrs:{"inline":true,"size":"small"}},[_c('el-form-item',{attrs:{"label":""}},[_c('el-input',{attrs:{"placeholder":"请输入活动名称/创建人"},model:{value:(_vm.keyword),callback:function ($$v) {_vm.keyword=$$v},expression:"keyword"}})],1),_c('el-form-item',[_c('el-button',{staticClass:"btn-blue",on:{"click":_vm.handleSearch}},[_vm._v("搜索")])],1)],1),_c('el-button',{staticClass:"btn-blue right",attrs:{"size":"small"},on:{"click":function () {
					_vm.row = '';
					_vm.type = 1;
					_vm.addVisible = true;
				}}},[_vm._v(" 添加群打卡 ")])],1),_c('div',{staticClass:"table"},[_c('el-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticStyle:{"width":"100%"},attrs:{"data":_vm.tableData,"tooltip-effect":"dark","header-cell-style":{
				color: '#333',
				fontSize: '14px',
				backgroundColor: '#F6F7FB',
				fontWeight: 'normal'
			}}},[_c('el-table-column',{attrs:{"prop":"name","label":"打卡任务名称","show-overflow-tooltip":""},scopedSlots:_vm._u([{key:"default",fn:function(scope){return _c('span',{staticClass:"ellipsis"},[_vm._v(_vm._s(scope.row.name))])}}])}),_c('el-table-column',{attrs:{"prop":"phone","label":"活动二维码"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-image',{ref:"el-image",staticStyle:{"width":"100px","height":"100px"},attrs:{"fit":"cover","z-index":9999,"preview-src-list":[_vm.url]}},[_c('div',{staticClass:"qrImg",attrs:{"slot":"error"},on:{"click":function($event){return _vm.handlePreview(_vm.urls[scope.$index])}},slot:"error"},[_c('vue-qr',{staticClass:"qr-code",staticStyle:{"cursor":"pointer","width":"100%"},attrs:{"text":scope.row.url,"margin":0,"colorDark":"#666","colorLight":"#fff","logoSrc":require('../../assets/images/logo.png'),"logoScale":0.2,"size":500,"callback":function (url) {
										_vm.urls[scope.$index] = url;
									}}})],1)])]}}])}),_c('el-table-column',{attrs:{"label":"打卡方式"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._v(" "+_vm._s(scope.row.mode == 1 ? '签到打卡' : '拍照打卡')+" ")]}}])}),_c('el-table-column',{attrs:{"label":"活动时间","width":"200px"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._v(" "+_vm._s(scope.row.start_time.replace(/-/g, '/'))+"-"+_vm._s(scope.row.end_time.replace(/-/g, '/'))+" ")]}}])}),_c('el-table-column',{attrs:{"label":"活动说明","show-overflow-tooltip":""},scopedSlots:_vm._u([{key:"default",fn:function(scope){return _c('span',{staticClass:"ellipsis"},[_vm._v(_vm._s(scope.row.rule))])}}])}),_c('el-table-column',{attrs:{"prop":"staff_name","label":"引流成员"}}),_c('el-table-column',{attrs:{"prop":"balance","label":"参与人数/打卡次数"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._v(" "+_vm._s(scope.row.number)+"/"+_vm._s(scope.row.times)+" ")]}}])}),_c('el-table-column',{attrs:{"prop":"add_user","label":"创建人"}}),_c('el-table-column',{attrs:{"prop":"create_at","label":"创建时间","width":"160px"}}),_c('el-table-column',{attrs:{"label":"操作"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-button',{staticStyle:{"margin-left":"10px","color":"#6881ec"},attrs:{"type":"text"},on:{"click":function () {
								_vm.row = JSON.parse(JSON.stringify(scope.row));
								_vm.type = 2;
								_vm.addVisible = true;
							}}},[_vm._v(" 编辑 ")]),(scope.row.is_release == 0)?_c('el-button',{staticStyle:{"color":"#6881ec"},attrs:{"type":"text"},on:{"click":function($event){return _vm.releasePunch(scope.row)}}},[_vm._v("发布")]):_vm._e(),_c('el-button',{staticStyle:{"color":"#6881ec"},attrs:{"type":"text"},on:{"click":function($event){return _vm.downloadImg(scope.$index, scope.row.name)}}},[_vm._v("下载")]),_c('el-button',{staticStyle:{"color":"#6881ec"},attrs:{"type":"text"},on:{"click":function () {
								_vm.id = scope.row.id;
								_vm.name = scope.row.name;
								_vm.mode = scope.row.mode;
								_vm.sVisible = true;
							}}},[_vm._v(" 统计 ")]),_c('el-button',{staticStyle:{"color":"#6881ec"},attrs:{"type":"text"},on:{"click":function($event){return _vm.$copy($event, scope.row.url)}}},[_vm._v("复制链接")])]}}])})],1)],1),(_vm.total !== 0)?_c('div',{staticClass:"pagination"},[_c('el-pagination',{attrs:{"current-page":_vm.page,"page-sizes":[10, 20, 40, 80],"page-size":_vm.limit,"layout":"total, sizes, prev, pager, next, jumper","total":_vm.total},on:{"size-change":function (pageSize) {
					_vm.limit = pageSize;
					_vm.page = 1;
					_vm.getClockList();
				},"current-change":function (currentPage) {
					_vm.page = currentPage;
					_vm.getClockList();
				}}})],1):_vm._e(),_c('add-clock',{attrs:{"visible":_vm.addVisible,"row":_vm.row,"type":_vm.type},on:{"close":_vm.addClose}}),_c('clock-statistic',{attrs:{"visible":_vm.sVisible,"id":_vm.id,"name":_vm.name,"mode":_vm.mode},on:{"close":_vm.sClose}})],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',[_c('span',{staticClass:"black"},[_vm._v("1、通过创建打卡任务，提升已有客户活跃度。")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',[_c('span',{staticClass:"black"},[_vm._v("2、打卡形式分为签到、拍照打卡两种类型，可根据活动性质与客户属性创建打卡任务。")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',[_c('span',{staticClass:"black"},[_vm._v("3、部分信息在发布之后无法编辑，请谨慎操作。")])])}]

export { render, staticRenderFns }