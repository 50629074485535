var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"clock"},[_c('el-dialog',{attrs:{"title":"打卡统计","visible":_vm.visible,"width":"1000px","before-close":_vm.close},on:{"update:visible":function($event){_vm.visible=$event}}},[_c('div',{staticClass:"scroll"},[_c('div',{staticClass:"my-title"},[_vm._v(_vm._s(_vm.name))]),_c('div',{staticClass:"chat"},[_c('div',[_c('div',{staticStyle:{"display":"flex","align-items":"center","justify-content":"space-between"}},[_c('span',[_vm._v("打卡情况总计")]),_c('el-select',{staticStyle:{"width":"150px"},attrs:{"size":"small","placeholder":"请选择"},on:{"change":function($event){return _vm.getTaskData()}},model:{value:(_vm.task_id),callback:function ($$v) {_vm.task_id=$$v},expression:"task_id"}},_vm._l((_vm.taskList),function(item,index){return _c('el-option',{key:index,attrs:{"label":'阶段任务' + item.sort,"value":item.id}})}),1)],1),_c('div',{attrs:{"id":"chat-pie"}})]),_c('p',{staticClass:"line"}),_c('div',[_c('div',{staticStyle:{"display":"flex","align-items":"center","justify-content":"space-between"}},[_c('span',[_vm._v("活动参与详情")]),_c('el-date-picker',{staticStyle:{"width":"230px"},attrs:{"clearable":false,"size":"small","value-format":"yyyy-MM-dd","type":"daterange","range-separator":"-","start-placeholder":"开始日期","end-placeholder":"结束日期","picker-options":{
								disabledDate: function (date) {
									return Date.parse(date) > Date.parse(new Date());
								}
							}},on:{"change":function($event){return _vm.getPartake()}},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}})],1),_c('div',{attrs:{"id":"chat-line"}})])]),_c('el-tabs',{attrs:{"type":"card"},model:{value:(_vm.activeName),callback:function ($$v) {_vm.activeName=$$v},expression:"activeName"}},[_c('el-tab-pane',{attrs:{"label":"参与详情","name":"1"}},[_c('div',{staticClass:"search"},[_c('el-form',{attrs:{"inline":true}},[_c('el-form-item',{attrs:{"label":"客户名称"}},[_c('el-input',{attrs:{"size":"small","placeholder":"请输入"},model:{value:(_vm.keyword),callback:function ($$v) {_vm.keyword=$$v},expression:"keyword"}})],1),_c('el-form-item',[_c('el-button',{staticClass:"btn-blue",attrs:{"size":"small"},on:{"click":_vm.handleSearch}},[_vm._v("查询")])],1)],1)],1),_c('div',{staticClass:"table"},[_c('el-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticStyle:{"width":"100%"},attrs:{"data":_vm.tableData,"tooltip-effect":"dark","header-cell-style":{
								color: '#333',
								fontSize: '14px',
								backgroundColor: '#F6F7FB',
								fontWeight: 'normal'
							}}},[_c('el-table-column',{attrs:{"prop":"nickname","label":"客户名称"}}),_c('el-table-column',{attrs:{"prop":"num","label":"打卡次数"}}),_c('el-table-column',{attrs:{"label":"已完成任务"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(scope.row.task.length != 0)?_c('div',_vm._l((scope.row.task),function(item,index){return _c('p',{key:index},[_vm._v("阶段任务"+_vm._s(item.sort)+"（"+_vm._s(item.punch_num)+"）")])}),0):_c('div',[_vm._v("--")])]}}])}),_c('el-table-column',{attrs:{"label":"未完成任务"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(scope.row.finish_task.length != 0)?_c('div',_vm._l((scope.row.finish_task),function(item,index){return _c('p',{key:index},[_vm._v("阶段任务"+_vm._s(item.sort)+"（"+_vm._s(item.punch_num)+"）")])}),0):_c('div',[_vm._v("--")])]}}])}),(_vm.mode == 2)?_c('el-table-column',{attrs:{"label":"打卡记录"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return _c('el-button',{attrs:{"type":"text"},on:{"click":function($event){return _vm.handleShowRecord(scope.row)}}},[_vm._v("查看打卡记录")])}}],null,false,2348348458)}):_vm._e()],1)],1)])],1),(_vm.total !== 0)?_c('div',{staticClass:"pagination"},[_c('el-pagination',{attrs:{"current-page":_vm.page,"page-sizes":[10, 20, 40, 80],"page-size":_vm.limit,"layout":"total, sizes, prev, pager, next, jumper","total":_vm.total},on:{"size-change":function (pageSize) {
							_vm.limit = pageSize;
							_vm.page = 1;
							_vm.getTableData();
						},"current-change":function (currentPage) {
							_vm.page = currentPage;
							_vm.getTableData();
						}}})],1):_vm._e()],1)]),_c('el-dialog',{attrs:{"title":"打卡记录","visible":_vm.pVisible,"width":"600px","before-close":_vm.handleClose},on:{"update:visible":function($event){_vm.pVisible=$event}}},[_c('div',{staticClass:"scroll"},[_c('div',{staticClass:"table"},[_c('el-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading1),expression:"loading1"}],staticStyle:{"width":"100%"},attrs:{"data":_vm.tableData1,"tooltip-effect":"dark","header-cell-style":{
						color: '#333',
						fontSize: '14px',
						backgroundColor: '#F6F7FB',
						fontWeight: 'normal'
					}}},[_c('el-table-column',{attrs:{"prop":"nickname","label":"打卡用户"}}),_c('el-table-column',{attrs:{"prop":"num","label":"打卡照片"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-image',{staticStyle:{"width":"100px","height":"100px"},attrs:{"fit":"cover","z-index":9999,"src":scope.row.punch_photo,"preview-src-list":[scope.row.punch_photo]},on:{"click":function($event){$event.stopPropagation();return _vm.$closeImg.apply(null, arguments)}}})]}}])}),_c('el-table-column',{attrs:{"prop":"punch_date","label":"打卡时间"}})],1)],1),(_vm.total1 !== 0)?_c('div',{staticClass:"pagination"},[_c('el-pagination',{attrs:{"current-page":_vm.page1,"page-sizes":[10, 20, 40, 80],"page-size":_vm.limit1,"layout":"total, sizes, prev, pager, next, jumper","total":_vm.total1},on:{"size-change":function (pageSize) {
							_vm.limit1 = pageSize;
							_vm.page1 = 1;
							_vm.getPunchPhotoList();
						},"current-change":function (currentPage) {
							_vm.page1 = currentPage;
							_vm.getPunchPhotoList();
						}}})],1):_vm._e()])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }