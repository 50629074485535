<template>
	<el-dialog :close-on-click-modal='false' :title="type==1?'添加打卡活动':'编辑打卡活动'" :visible.sync="visible" width="600px"
		:before-close="close">
		<el-form label-position="left" label-width="120px" ref='form' :model="form" style="width: 90%;margin: 0 5%;">
			<el-form-item label="打卡名称" prop="name" :rules="[{required:true,message:'请输入打卡名称',trigger: 'blur'}]">
				<el-input :disabled="row.is_release==1" maxlength="30" placeholder="请输入活动名称" size="small"
					v-model="form.name"></el-input>
			</el-form-item>
			<el-form-item v-if="type==1||row.is_release==0" label="打卡时间" prop="date"
				:rules="[{required:true,message:'请选择打卡时间',trigger: 'change'}]">
				<el-date-picker :picker-options="{
			      disabledDate: (date) => {
			        return Date.parse(date) < Date.parse(new Date(new Date().getTime()-24*3600*1000));
			      },
			    }" class="date-picker" size="small" v-model="form.date" value-format='yyyy-MM-dd' type="daterange"
					range-separator="-" start-placeholder="开始日期" end-placeholder="截止日期">
				</el-date-picker>
			</el-form-item>
			<el-form-item v-else-if="row.is_release==1" label="打卡时间" prop="date"
				:rules="[{required:true,message:'请选择打卡截止时间',trigger: 'change'}]">
				<el-date-picker class="start-time" disabled size="small" v-model="form.date[0]" type="date"
					value-format='yyyy-MM-dd' placeholder="开始日期">
				</el-date-picker>
				<el-date-picker class="end-time" :clearable='false' size="small" :picker-options="{
			      disabledDate: (date) => {
			        if(new Date(form.date[0])<new Date(new Date().getTime()-24*3600*1000)){
			        	return Date.parse(date) < Date.parse(new Date(new Date().getTime()-24*3600*1000));
			        }else{
			        	return Date.parse(date) < Date.parse(new Date(form.date[0]));
			        }
			      },
			    }" v-model="form.date[1]" type="date" value-format='yyyy-MM-dd' placeholder="截止日期">
				</el-date-picker>
			</el-form-item>
			<el-form-item label="打卡规则" style="margin-top: 5px;" prop="rule"
				:rules="[{required:true,message:'请输入打卡规则',trigger: 'blur'}]">
				<el-input :disabled="row.is_release==1" resize="none" :autosize='{minRows: 3,maxRows: 3}'
					maxlength="200" show-word-limit type="textarea" size="small" v-model="form.rule">
				</el-input>
			</el-form-item>
			<el-form-item label="打卡方式" required>
				<el-radio-group :disabled="row.is_release==1" v-model="form.mode">
					<el-radio :label="1">签到打卡</el-radio>
					<el-radio :label="2">拍照打卡</el-radio>
				</el-radio-group>
			</el-form-item>
			<el-form-item label="打卡类型" required>
				<el-radio-group :disabled="row.is_release==1" v-model="form.type">
					<el-radio :label="1">连续打卡</el-radio>
					<el-radio :label="2">累计打卡</el-radio>
				</el-radio-group>
			</el-form-item>
			<el-form-item label="任务列表" required>
				<div class="add-wrap">
					<p class="ctitle" v-if='row.is_release!=1' @click="handleAddForm">添加阶段打卡任务</p>
					<div class="scroll">
						<el-form :disabled="row.is_release==1" label-position='left' label-width="120px" :model="form"
							ref='taskForm'>
							<div class="task" v-for="(val,index) in form.task">
								<el-form-item :label="`任务`" label-width="40px">{{val.sort}}
									<el-button style="margin-left: 15px;" @click='handleDelForm(index)' v-if="index!=0"
										type="danger" size="mini" icon="el-icon-delete">
									</el-button>
								</el-form-item>
								<el-form-item :label="form.type==1?'连续打卡次数':'累计打卡次数'"
									:rules="[{required:true,message:'请输入打卡次数',trigger: 'blur'},{ type: 'number', min:1,message:'打卡次数不能为零'}]"
									:prop="'task.' + index + '.punch_num'">
									<el-input size="small" :min="1" v-model.number="val.punch_num" @input="(value)=>{
										val.punch_num = $isnumber(value)
									}" placeholder="请输入打卡次数"></el-input>
								</el-form-item>
								<el-form-item label="奖品名称" :rules="[{required:true,message:'请输入奖品名称',trigger: 'blur'}]"
									:prop="'task.' + index + '.reward'">
									<el-input size="small" v-model="val.reward" placeholder="请输入奖品名称"></el-input>
								</el-form-item>
							</div>
						</el-form>
					</div>
				</div>
			</el-form-item>
			<el-form-item label="引流成员" prop="staff_user_id"
				:rules="[{required:true,message:'请选择引流成员',trigger: 'change'}]">
				<el-select :disabled="row.is_release==1" :loading='loading' size="small" v-model="form.staff_user_id"
					placeholder="请选择">
					<el-option v-for="(item,index) in staffList" :key="index" :label="item.name" :value="item.user_id">
					</el-option>
				</el-select>
				<el-tooltip class="item" effect="dark" content="完成打卡任务后领取奖品添加的成员" placement="top-start">
					<i class="iconfont icon-question-circle" style="margin-left: 10px"></i>
				</el-tooltip>
			</el-form-item>
		</el-form>
		<span slot="footer" class="dialog-footer">
			<el-button size="small" class="btn-white" @click="close">取 消</el-button>
			<el-button size="small" class="btn-blue" @click="handleSubmit">确 定</el-button>
		</span>
	</el-dialog>
</template>

<script>
	import {
		reqGetSoloStaff,
		reqAddPunch,
		reqEditPunch
	} from '@/api/index';
	export default {
		props: ['visible', 'row', 'type'],
		data() {
			return {
				form: {
					name: '',
					date: '',
					rule: '',
					type: 1,
					mode: 1,
					staff_user_id: '',
					task: [{
						sort: 1,
						punch_num: '',
						reward: ''
					}, ]
				},
				staffList: [],
				loading: false
			}
		},
		watch: {
			visible(val) {
				if (val) {
					this.$nextTick(() => {
						this.$refs['form'].clearValidate();
					})
					this.getStaffList();
					if (this.row) {
						this.form = {
							name: this.row.name,
							date: [this.row.start_time, this.row.end_time],
							rule: this.row.rule,
							type: this.row.type,
							mode: this.row.mode,
							staff_user_id: this.row.staff_user_id,
							task: this.row.task
						}
					}
				}
			},
		},
		computed: {
			phone() {
				return JSON.parse(localStorage.getItem('huankemao_user_info')).phone;
			}
		},
		methods: {
			close() {
				Object.assign(this.$data, this.$options.data());
				this.$nextTick(() => {
					this.$refs['form'].clearValidate();
					this.$refs['taskForm'].clearValidate();
				})
				this.$emit('close');
			},
			handleSubmit() {
				var flag1 = false,
					flag2 = false;
				this.$refs['form'].validate((valid) => {
					if (valid) {
						flag1 = true;
					} else {
						flag2 = false;
					}
				});
				this.$refs['taskForm'].validate((valid) => {
					if (valid) {
						flag2 = true;
					} else {
						flag2 = false;
					}
				});
				if (flag1 && flag2) {
					var submit = true;
					var params = JSON.parse(JSON.stringify(this.form));
					for (let i = 0; i < params.task.length; i++) {
						if (i < params.task.length - 1 && params.task[i].punch_num < params.task[i + 1].punch_num) {
							continue;
						} else if (i == params.task.length - 1) {
							continue;
						} else {
							this.$message.error('新增任务打卡次数应大于前置任务打卡次数');
							submit = false;
							return;
						}
					}
					if (submit) {
						params.start_time = params.date[0];
						params.end_time = params.date[1];
						params.add_user = this.phone;
						this.row.id ? params.id = this.row.id : '';
						delete params.date;
						this.addPunch(params);
					}
				}
			},
			handleAddForm() {
				this.form.task.push({
					sort: this.form.task.length + 1,
					punch_num: '',
					reward: ''
				})
				this.$nextTick(() => {
					var oScroll = document.getElementsByClassName('scroll')[0];
					oScroll.scrollTop = oScroll.scrollHeight;
				})
			},
			handleDelForm(index) {
				this.form.task.splice(index, 1);
			},
			addPunch(params) {
				reqAddPunch(params).then(res => {
					this.$bus.$emit('updateData');
					this.$message.success(res.msg);
					this.close();
				})
			},
			getStaffList() {
				this.loading = true;
				reqGetSoloStaff({
					page: 1,
					limit: 1000
				}).then(res => {
					this.staffList = res.data;
					this.loading = false;
				})
			}
		}
	}
</script>

<style lang='less' scoped>
	.date-picker {
		width: 100%;
	}

	.start-time {
		width: 192px;

		/deep/.el-input__inner {
			border-radius: 4px 0 0 4px;
		}
	}

	.end-time {
		width: 192px;

		/deep/.el-input__inner {
			border-radius: 0 4px 4px 0;
		}
	}

	.add-wrap {
		border: 1px solid #DCDFE6;
		border-radius: 5px;
		padding: 5px 15px;

		.ctitle {
			width: 100px;
			font-size: 12px;
			color: #6881EC;
			cursor: pointer;
		}

		.scroll {
			max-height: 260px !important;
			overflow-x: hidden;
			overflow-y: scroll;
			padding-right: 10px;
			padding-bottom: 10px;
		}
	}
</style>
